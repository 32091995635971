@tailwind base;
@tailwind components;
@tailwind utilities;

.welcome {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
}
