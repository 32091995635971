.scroll-thumb {
    // height: calc(100% - 150px);
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 6px;
        background-color: #00000020;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px #00000027;
        background-color: $COLOR_SECONDARY;
    }
}

.scroll-thumb-x {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        height: 10px;
        background-color: #2fb2ff;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 10px #ee7e22;
        background-color: #ee7e22;
    }
}

.scroll-thumb-pies-x {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        height: 10px;
        background-color: #2fb2ff;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 10px #ee7e22;
        background-color: #ee7e22;
    }
}