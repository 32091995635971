@font-face {
  font-family: "Poppins-Bold"; 
  src: url("/assets/fonts/Poppins-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("/assets/fonts/Poppins-Light.otf") format("opentype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("/assets/fonts/Poppins-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("/assets/fonts/Poppins-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("/assets/fonts/Poppins-Medium.otf") format("opentype");
}
