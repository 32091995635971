.loading {
  text-align: center;
  .wrapper {
    width: 100%;
    height: 100%;
  }
  span {
    font-size: 24px;
    font-family: "Poppins-Bold";
    padding: 2px;
  }
  .container {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    .logo {
      filter: brightness(0) !important;
    }
  }
  .path {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: dash 4s linear alternate infinite;
    -webkit-box-shadow: 0px 0px 15px 5px black;
    -moz-box-shadow: 0px 0px 15px 5px black;
    box-shadow: 0px 0px 15px 5px black;
    filter: url(#blur-filter);
    stroke: black;
    stroke-linecap: round;
  }
  .loader span {
    animation: blurFadeInOut 6s ease-in-out infinite alternate;
    color: transparent;
    text-shadow: 0px 0px 1px white;
    opacity: 0;
    -webkit-animation: blurFadeInOut 6s ease-in-out infinite alternate;
  }
  .loader span:nth-child(2) {
    animation-delay: 0.15s;
  }
  .loader span:nth-child(3) {
    animation-delay: 0.3s;
  }
  .loader span:nth-child(4) {
    animation-delay: 0.45s;
  }
  .loader span:nth-child(5) {
    animation-delay: 0.6s;
  }
  .loader span:nth-child(6) {
    animation-delay: 0.75s;
  }
  .loader span:nth-child(7) {
    animation-delay: 0.9s;
  }
  @keyframes blurFadeInOut {
    0% {
      opacity: 0;
      text-shadow: 0px 0px 40px white;
      /*transform: scale(0.9);*/
    }
    20%,
    75% {
      opacity: 1;
      text-shadow: 0px 0px 1px white;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      text-shadow: 0px 0px 50px white;
      /*transform: scale(0);*/
    }
  }
  @keyframes dash {
    from {
      stroke-dashoffset: 200;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
}
