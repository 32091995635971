// font
@import "./components/font.scss";
// global base
@import "./components/base.scss";
@import "./components/settings.scss";
@import "./components/scroll.scss";
@import "./components/loader.scss";

li.dot {
  margin: 0 3px !important;
  background-color: #002d96 !important;
}

div.intercom-lightweight-app-launcher {
  margin-bottom: 80px !important;
}

div.intercom-dfosxs {
  transform: translate(0, -80px) !important;
}